import axios from "axios";
import config from "../../config/config";

// REACT_APP_API_URL
const {apiURL} = config

export default axios.create({
    baseURL: apiURL,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
  });