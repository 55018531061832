import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';




const ModalPopUp = (props) => {

    const {
        isOpen = false,
        children = "",
        className = "",
        onClose } = props;

    const [open, setOpen] = React.useState(isOpen);


    /**
     * Handles the close click
     */
    const handleClose = () => {
        setOpen(false);

        if (typeof onClose === "function") {
            onClose();
        }
    };



    return (
        <div>
            <Dialog
                className={`${className}`}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle> */}

                <DialogContent>
                    <div className='back-btn-wrp f-wrp'>
                        <IconButton
                            onClick={onClose}
                            aria-label="Go Back">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M2 2L12 12M2 12L12 2" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </IconButton>

                    </div>
                    {(typeof children === 'string') ? <DialogContentText id="alert-dialog-description">
                        {children}
                    </DialogContentText> : <>{children}</>}
                </DialogContent>

            </Dialog>
        </div>
    );
}

ModalPopUp.propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.any,
    onClose: PropTypes.func,
    className: PropTypes.string,
}

export default ModalPopUp;